import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { LocalStorageService } from "../shared/local-storage.service";
@Injectable({
  providedIn: "root",
})
export class ApiService {
  baseUrl: any;

  constructor(
    private http: HttpClient,
    private _localStorageService: LocalStorageService
  ) {
    this.baseUrl = environment.apiUrl;
  }

  getRequest(path: any, addDeviceTken = false): Observable<any> {
    const url = this.baseUrl + path;
    const headers = addDeviceTken ? this.addAccessHeaderToken() : null;

    return addDeviceTken === true
      ? this.http.get(url, { headers })
      : this.http.get(url);
  }

  postRequest(path: any, data: any, addDeviceTken = false): Observable<any> {
    const headers = addDeviceTken
      ? this.addAccessHeaderToken()
      : new HttpHeaders({
          "Content-Type": "application/x-www-form-urlencoded",
        });
    data = this.bindData(data).toString();
    const url = this.baseUrl + path;
    return this.http.post(url, data, { headers });
  }

  deleteRequest(path: any): Observable<any> {
    const url = this.baseUrl + path;
    const headers = this.addAccessHeaderToken();
    return this.http.delete(url, { headers });
  }

  putRequest(path: any, data: any): Observable<any> {
    const url = this.baseUrl + path;
    const headers = this.addAccessHeaderToken();
    data = this.bindData(data).toString();
    return this.http.put(url, data, { headers });
  }
  formUrlEncodedHeadersGet() {
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return headers;
  }
  bindData(data: any) {
    const keys = Object.keys(data);
    const body = new URLSearchParams();
    keys.map((ele: any) => {
      body.set(ele, data[ele]);
    });
    return body;
  }

  addAccessHeaderToken() {
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      "httpx-thetatech-accesstoken": this._localStorageService.getObjectsValueByKeyNameOrFullInfo(
        "deviceInfo",
        "deviceAccessToken"
      ),
    });
    return headers;
  }
  postUploadRequest(
    path: any,
    data: any,
    addDeviceTken = false
  ): Observable<any> {
    const headers = new HttpHeaders({
      "httpx-thetatech-accesstoken": this._localStorageService.getObjectsValueByKeyNameOrFullInfo(
        "deviceInfo",
        "deviceAccessToken"
      ),
    });
    // data = this.bindData(data).toString();
    const url = this.baseUrl + path;
    return this.http.post(url, data, { headers });
  }
}
