import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from "../../../shared/local-storage.service";
import { Roles, IsCompany, IsProfileCompleted } from "../../../shared/enum";
import { SidebarService } from "../../../services/sidebar/sidebar.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  @ViewChild("navUl") navUlElement;
  userRole: any;
  user: any;
  roleEnum = Roles;
  isCompanyEnum = IsCompany;
  isProfileCompletedEnum = IsProfileCompleted;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    public sidebarService: SidebarService
  ) {}

  ngOnInit() {
    this.userRole = this.localStorageService.getObjectsValueByKeyNameOrFullInfo(
      "userInfo",
      "role"
    );
    this.user =
      this.localStorageService.getObjectsValueByKeyNameOrFullInfo("userInfo");
  }
  sidebarToggled() {
    const ulTag = this.navUlElement.nativeElement.classList;
    if (ulTag.contains("toggled")) {
      ulTag.remove("toggled");
    } else {
      ulTag.add("toggled");
    }
  }

  isActive(name) {
    return this.router.url.includes(name);
  }
}
