import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgetPasswordComponent } from './forget-password.component';
import { ForgetPasswordRoutes } from './forget-password.routing';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ForgetPasswordRoutes,
    ReactiveFormsModule
  ],
  declarations: [ForgetPasswordComponent]
})
export class ForgetPasswordModule { }
