import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor() { }

  PAGINATION = {
    default_page_size: 10,
    rating_defaultpagesize: 5,
  };
  imgSrc = "assets/img/user-default.jpg";
  userImageSrc = 'assets/img/images/default-avatar.png';
  driverImageSrc = "assets/img/images/user.png";
  // driverImageSrc = "https://uifaces.co/our-content/donated/vIqzOHXj.jpg";
  restaurantImageSrc = 'assets/img/images/no-img.png';
}
