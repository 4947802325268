import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverReportService } from 'src/app/services/driver-report/driver-report.service';
import { RestaurantReportService } from 'src/app/services/restaurant-report/restaurant-report.service';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-driver-reports',
  templateUrl: './driver-reports.component.html',
  styleUrls: ['./driver-reports.component.scss']
})
export class DriverReportsComponent implements OnInit {
  @ViewChild("cancel") cancelBtn;

  pageSize: any;
  selectedPage: any;
  isLoading: boolean = false;
  finalPage = 1;
  totalLength: number = 0;
  pageNumber: number = 1;
  collectionSize: number;
  driverReportData: any = [];
  driverReportsForm: FormGroup;
  year: any = [];
  orderIDs : any;

  constructor(
    private route: ActivatedRoute, 
    private common: CommonService, 
    private router: Router, 
    private fb: FormBuilder,
    private _driverService: DriverReportService,
    private _restaurantReportService: RestaurantReportService) { }

  ngOnInit() {
    const pageSize = this.route.snapshot.queryParamMap.get("pageNo");
    this.pageNumber = pageSize === null ? 1 : parseInt(pageSize);
    this.selectedPage = this.pageNumber;
    this.pageSize = this.common.PAGINATION.default_page_size;
    this.formLoad();
    this.getYear();
    this.driverReportsForm.controls["status"].setValue(0);
    this.getDriverData();
  }

  formLoad() {
    this.driverReportsForm = this.fb.group({
      year: [""],
      month: [""],
      quarterType: [""],
      status: [""]
    });
  }

  getDriverData() {
    this.isLoading = true;
    this._driverService
      .getAllDrivers(this.driverReportsForm.value.year, this.driverReportsForm.value.month, this.driverReportsForm.value.quarterType, this.driverReportsForm.value.status, this.selectedPage, this.pageSize)
      .subscribe(
        (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== "" &&
            Array.isArray(res.response.list) &&
            res.response.list.length > 0
          ) {
            this.totalLength = res.response.total;
            this.collectionSize = res.response.list.length;

            this.driverReportData = res.response.list;
            this.finalPage = this.selectedPage;
          }
          else {
            this.driverReportData = [];
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;

          throw err;
        }
      );
  }

  onPageSizeChange(event) {
    this.pageSize = event.target.value;
    this.getDriverData();
  }

  setPaginationPage(event) {
    this.selectedPage = event;
    this.pageNumber = event;
    this.router.navigate(["/driver-reports"], {
      queryParams: {
        pageNo: event,
      },
    });
    this.getDriverData();
  }
  
  isBtnDisabled() {
    let isDisabled = true;
    for (const field in this.driverReportsForm.controls) { // 'field' is a string
      if (this.driverReportsForm.controls[field].value !== "") {
        isDisabled = false;
      } else {
        isDisabled = true;
        break;
      }
    }
    return isDisabled;
  }
  resetFilter() {
    this.driverReportsForm.controls['status'].patchValue(0);
    this.driverReportsForm.controls['year'].patchValue("");
    this.driverReportsForm.controls['month'].patchValue("");
    this.driverReportsForm.controls['quarterType'].patchValue("");
    this.router.navigate(["/driver-reports"], {
      queryParams: {
        pageNo: 1,
      },
    });
    this.getDriverData();
  }
  applyFilter() {
    this.pageNumber = 1;
    this.selectedPage = 1;
    this.router.navigate(["/driver-reports"], {
      queryParams: {
        pageNo: 1,
      },
    });
    this.getDriverData();
  }

  getYear(){
    var year = new Date().getFullYear();
    this.driverReportsForm.controls["year"].setValue(year);
    this.year = [];
    this.year.push(year);
    for (var i = 1; i < 5; i++) {
      this.year.push(year - i);
    }
  }

  getOrderIds(orderids){
    this.orderIDs = JSON.stringify(orderids);
  }

  payment(){
    this._restaurantReportService.adminPay(this.orderIDs).subscribe(res => {
      if(res !== undefined &&
        res !== null &&
        res !== "" &&
        res.response !== ""){
          this.cancelBtn.nativeElement.click();
          this.getDriverData();
      } 
    },
    err => {
      this.cancelBtn.nativeElement.click();
      throw err;
    });
   
  }

  download() {
    this._driverService.getData(this.driverReportsForm.value.year, this.driverReportsForm.value.month, this.driverReportsForm.value.quarterType, this.driverReportsForm.value.status).subscribe(response => {
      if(response.response.record){
        window.open(response.response.fileUrl);
      } else{
        return;
      }
      });
  }

}
