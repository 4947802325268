import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private apiService: ApiService) { }

  adminLogin(data: any): Observable<any> {
    const path = `/adminLogin`;
    const obj = {
      devicePlatform: "web",
      deviceUniqueId: "webadmin",
      deviceToken:
        "APA91bHjsWaDEq8ASKK5DRzB9GBsH7Z5sasdsadsadsadsadsar965674857YP_CCMm4m87Zw7RujdTV6m525S6U9ssEt_84dTqu0GuAFDbXXUmAhCK63JjnMiA0fPtC1cdsadasdsaderer",
      deviceModel: "web",
      os: "ubuntu",
    };
    Object.assign(data, obj);

    return this.apiService.postRequest(path, data);
  }
  forgetPassword(data: any) {
    const path = `/forgotPassword`;
    return this.apiService.postRequest(path, data);
  }
  checkResetToken(data: any) {
    const path = `/checkResetToken`;
    return this.apiService.postRequest(path, data);
  }

  resetPassword(data: any) {
    const path = `/resetPassword`;
    return this.apiService.postRequest(path, data);
  }
  changePassword(data: any) {
    const path = `/changePassword`;
    return this.apiService.postRequest(path, data, true);
  }

  login(data: any): Observable<any> {
    const path = `/login`;
    const obj = {
      devicePlatform: "web",
      deviceUniqueId: "webadmin",
      deviceToken:
        "APA91bHjsWaDEq8ASKK5DRzB9GBsH7Z5sasdsadsadsadsadsar965674857YP_CCMm4m87Zw7RujdTV6m525S6U9ssEt_84dTqu0GuAFDbXXUmAhCK63JjnMiA0fPtC1cdsadasdsaderer",
      deviceModel: "web",
      os: "ubuntu",
    };
    Object.assign(data, obj);

    return this.apiService.postRequest(path, data);
  }
}
