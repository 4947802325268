import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { passwordValidators } from '../reset-password/password.validators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  @ViewChild("cancel") cancelBtn;
  @ViewChild("chCls") chnageCancelBtn;

  constructor(private router: Router, private fb: FormBuilder, private _authService: AuthService, private localStorageService: LocalStorageService) { }
  changePasswordForm: FormGroup;
  submitted: boolean = false;

  errMsg: string;
  isApiWorked: boolean = false;


  ngOnInit() {
    let bodyclass = document.getElementsByTagName("body");
    bodyclass[0].classList.remove("bg-gradient-primary");
    this.formLoad();
  }
  logOut() {
    this.cancelBtn.nativeElement.click();
    localStorage.clear();
    this.router.navigateByUrl("/login");
  }
  formLoad() {
    this.changePasswordForm = this.fb.group(
      {
        newPassword: ["", [Validators.required, Validators.minLength(6)]],
        oldPassword: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", Validators.required],
      },
      {
        validator: passwordValidators.PasswordShouldMatch(
          "newPassword",
          "confirmPassword"
        ),
      }
    );
  }
  get changePasswordF() {
    return this.changePasswordForm.controls;
  }
  get newPassword() {
    return this.changePasswordForm.get('newPassword');
  }
  changePassword() {
    this.submitted = true;
    this.errMsg = undefined;
    if (this.changePasswordForm.invalid) return true;
    this.isApiWorked = true;
    this.changePasswordForm.value.passwordchangeToken = this.localStorageService.getObjectsValueByKeyNameOrFullInfo('deviceInfo', 'deviceAccessToken');
    this._authService.changePassword(this.changePasswordForm.value).subscribe(
      (res: any) => {
        this.isApiWorked = false;
        if (
          res !== undefined &&
          res !== null &&
          res !== "" &&
          res.errorMsg === ""
        ) {
          this.chnageCancelBtn.nativeElement.click();
        } else {
          this.errMsg = res.errorMsg;
        }
      },
      (err) => {
        this.chnageCancelBtn.nativeElement.click();
        this.isApiWorked = false;
        throw err;
      }
    );
  }
}
