import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "src/app/shared/guards/auth.guard";
import { HomeComponent } from "./home.component";


const routes: Routes = [
  { path: "", component: HomeComponent, canActivate: [AuthGuard], },
  { path: "home", component: HomeComponent, canActivate: [AuthGuard] },

];

export const HomeRoutes = RouterModule.forChild(routes);
