import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverRequestComponent } from './driver-request.component';
import { DriverRoutes } from './driver-request.routing';
import { DriverDetailComponent } from './driver-detail/driver-detail.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule } from '@angular/forms';
import { Daterangepicker } from 'ng2-daterangepicker';
@NgModule({
  imports: [
    CommonModule,
    DriverRoutes,
    NgbModule,
    ReactiveFormsModule,
    Daterangepicker
  ],
  declarations: [DriverRequestComponent, DriverDetailComponent]
})
export class DriverRequestModule { }
