import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class DriverReportService {

  constructor(private apiService: ApiService) { }

  getAllDrivers(year = "", month = "", quarterType = '', isPaid = '', pageNo = "", pageSize = "",  isPagination = 1 ) {
    const path = `/driver/report?year=${year}&month=${month}&quarterType=${quarterType}&isPaid=${isPaid}&isPagination=${isPagination}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.apiService.getRequest(path, true);
  }

  getData(year = "", month = "", quarterType = '', isPaid = '') {
    const path = `/driver/report/download?year=${year}&month=${month}&quarterType=${quarterType}&isPaid=${isPaid}`;
    return this.apiService.getRequest(path, true);
  }

}
