import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  forgetpasswordForm: FormGroup;
  errMsg: string;
  isApiWorked: boolean = false;
  submitted: boolean = false;
  responseMsg: Promise<boolean>;
  constructor(
    private fb: FormBuilder,
    private _authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    let bodyclass = document.getElementsByTagName("body");
    bodyclass[0].classList.add("bg-gradient-primary");
    this.formLoad();
  }
  formLoad() {
    this.forgetpasswordForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }
  get forgetF() {
    return this.forgetpasswordForm.controls;
  }

  forgetPassword() {
    this.submitted = true;
    this.errMsg = undefined;
    this.responseMsg = undefined;
    if (this.forgetpasswordForm.invalid) return true;
    this.isApiWorked = true;
    this._authService.forgetPassword(this.forgetpasswordForm.value).subscribe(
      (res: any) => {
        this.isApiWorked = false;
        if (
          res !== undefined &&
          res !== null &&
          res !== "" &&
          res.errorMsg === ""
        ) {
          this.responseMsg = res.response;
          this.router.navigateByUrl("/login");
        } else {
          this.errMsg = res.errorMsg;
        }
      },
      (err) => {
        this.isApiWorked = false;
        throw err;
      }
    );
  }

}
