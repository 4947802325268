import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DaterangepickerComponent } from 'ng2-daterangepicker';
import { RestaurantRequestService } from 'src/app/services/restaurant-request/restaurant-request.service';
import { UsersService } from 'src/app/services/users/users.service';
import { CommonService } from 'src/app/shared/common.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  pageSize: any;
  selectedPage: any;
  isLoading: boolean = false;
  keyWord: "";
  finalPage = 1;
  totalLength: number = 0;
  pageNumber: number = 1;
  collectionSize: number;
  usersData: any = [];
  userForm: FormGroup;
  name: any;
  filterStartDate: any = '';
  filterEndDate: any = '';
  public daterange: any = {};
  makeRetailerLoading: boolean = false;

  @ViewChild(DaterangepickerComponent)
  public picker!: DaterangepickerComponent;

  @ViewChild('datePicker1')
  public datePicker1: any;

  constructor(
    private _userService: UsersService, 
    private route: ActivatedRoute, 
    private common: CommonService, 
    private router: Router, 
    private fb: FormBuilder,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    const pageSize = this.route.snapshot.queryParamMap.get("pageNo");
    this.pageNumber = pageSize === null ? 1 : parseInt(pageSize);
    this.selectedPage = this.pageNumber;
    this.pageSize = this.common.PAGINATION.default_page_size;
    this.formLoad();
    this.getUsersData();
  }

  ngAfterViewInit() {
    // // Set default value to null after view initialization
    this.datePicker1.nativeElement.value = '';
  }


  formLoad() {
    this.userForm = this.fb.group({
      keyword: [""],
      registerType: [""],
      startDate: [""],
      endDate: [""]
    });
  }

  getUsersData() {
    this.isLoading = true;
    this._userService
      .getAllUsers(this.selectedPage, this.pageSize, this.userForm.value.keyword, this.userForm.value.registerType, this.filterStartDate, this.filterEndDate)
      .subscribe(
        (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== "" &&
            Array.isArray(res.response.list) &&
            res.response.list.length > 0
          ) {
            this.totalLength = res.response.total;
            this.collectionSize = res.response.list.length;

            this.usersData = res.response.list;
            this.finalPage = this.selectedPage;
          }
          else {
            this.usersData = [];
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;

          throw err;
        }
      );
  }


  onPageSizeChange(event) {
    this.pageSize = event.target.value;
    this.getUsersData();
  }
  setPaginationPage(event) {
    this.selectedPage = event;
    this.pageNumber = event;
    this.router.navigate(["/users"], {
      queryParams: {
        pageNo: event,
      },
    });
    this.getUsersData();
  }
  public options: any = {
    locale: { format: 'DD-MMM-yyyy' },
    alwaysShowCalendars: false,
  };

  public selectedDate(value: any) {
    // this is the date  selected
    
    const startDate = value.start._d;
    const filterStartDate = new Date(startDate);
    var dd = String(filterStartDate.getDate()).padStart(2, '0');
    var mm = String(filterStartDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = filterStartDate.getFullYear();
    this.filterStartDate = yyyy + '-' + mm + '-' + dd;

    const endDate = value.end._d;
    const filterEndDate = new Date(endDate);
    var dd = String(filterEndDate.getDate()).padStart(2, '0');
    var mm = String(filterEndDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = filterEndDate.getFullYear();
    this.filterEndDate = yyyy + '-' + mm + '-' + dd;

    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;
    this.setDatePickerValue();
  }

  public applySelectedDate(value: any) {
    // this is the date  selected
    
    const startDate = value.startDate._d;
    const filterStartDate = new Date(startDate);
    var dd = String(filterStartDate.getDate()).padStart(2, '0');
    var mm = String(filterStartDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = filterStartDate.getFullYear();
    this.filterStartDate = yyyy + '-' + mm + '-' + dd;

    const endDate = value.endDate._d;
    const filterEndDate = new Date(endDate);
    var dd = String(filterEndDate.getDate()).padStart(2, '0');
    var mm = String(filterEndDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = filterEndDate.getFullYear();
    this.filterEndDate = yyyy + '-' + mm + '-' + dd;

    this.daterange.start = value.startDate;
    this.daterange.end = value.endDate;
    this.daterange.label = value.label;
    this.setDatePickerValue();
  }



  calendarCanceled() {
    this.filterStartDate = '';
    this.filterEndDate = '';
    this.picker.datePicker.setStartDate(new Date());
    this.picker.datePicker.setEndDate(new Date());
    this.setDatePickerValue();
  }

  setDatePickerValue() {
    this.userForm.controls['startDate'].patchValue(this.filterStartDate);
    this.userForm.controls['endDate'].patchValue(this.filterEndDate);
  }
  isBtnDisabled() {
    let isDisabled = true;
    for (const field in this.userForm.controls) { // 'field' is a string
      if (this.userForm.controls[field].value !== "") {
        isDisabled = false;
        break;
      }
    }
    return isDisabled;
  }
  resetFilter() {
    this.calendarCanceled();
    this.userForm.controls['keyword'].patchValue("");
    this.userForm.controls['registerType'].patchValue("");
    this.datePicker1.nativeElement.value = '';
    this.router.navigate(["/users"], {
      queryParams: {
        pageNo: 1,
      },
    });
    this.getUsersData();
  }
  applyFilter() {
    this.pageNumber = 1;
    this.selectedPage = 1;
    this.router.navigate(["/users"], {
      queryParams: {
        pageNo: 1,
      },
    });
    this.getUsersData();
  }

  makeRetailer(id){
    this.makeRetailerLoading = true;
    this._userService.makeRetailer(id)
      .subscribe((res) => {
        this.getUsersData();
        this.toastrService.success(res.response);
        this.makeRetailerLoading = false;
      }, (err) => {
        this.makeRetailerLoading = false;
        throw err;
      });
  }

}
