import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestaurantReportsComponent } from './restaurant-reports.component';
import { RestaurantReportsRoutes } from './restaurant-reports.routing';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    RestaurantReportsRoutes,
    NgbModule,
    ReactiveFormsModule
  ],
  declarations: [RestaurantReportsComponent]
})
export class RestaurantReportsModule { }
