import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserComponent } from './user.component';

const routes: Routes = [
  { path: '', component: UserComponent, canActivate: [AuthGuard] },
  {
    path: "user-detail",
    component: UserDetailComponent,
    canActivate: [AuthGuard]

  }
];

export const UserRoutes = RouterModule.forChild(routes);
