import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class DriverRequestService {

  constructor(private apiService: ApiService) { }

  getAllDriverRequest(pageNo = "", pageSize = "", status = "", name = "", startDate = '', endDate = '', isPagination = 1) {
    const path = `/drivers?isPagination=${isPagination}&pageNo=${pageNo}&pageSize=${pageSize}&status=${status}&keyword=${name}&startDate=${startDate}&endDate=${endDate}`;
    return this.apiService.getRequest(path, true);
  }

  driverStatus(data: any) {
    const path = `/driver/status`;
    return this.apiService.putRequest(path, data);
  }

  driverDetails(id: any) {
    const path = `/driver/detail/${id}`;
    return this.apiService.getRequest(path, true);
  }
  driverBankDetails(id: any) {
    const path = `/driver/bankDetail/${id}`;
    return this.apiService.getRequest(path, true);
  }
}
