import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { LocalStorageService } from "../local-storage.service";
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private _localStorageService: LocalStorageService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const adminRoleInfo = this._localStorageService.getObjectsValueByKeyNameOrFullInfo(
      "userInfo",
      "role"
    );
    const deviceToken = this._localStorageService.getObjectsValueByKeyNameOrFullInfo(
      "deviceInfo",
      "deviceToken"
    );
    const deviceInfo = this._localStorageService.getObjectsValueByKeyNameOrFullInfo(
      "deviceInfo",
      ""
    );

    if (adminRoleInfo && deviceToken && deviceInfo) {
      return true;
    } else {
      localStorage.clear();
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
