<div class="container-fluid">

  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Users</h1>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-12 col-xl-12 col-sm-12">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">Filters for Users</h6>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <form [formGroup]="userForm">
            <div class="row">
              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label>Name / Email / Phone </label>
                  <input type="text" class="form-control" formControlName="keyword" placeholder="Name / Email / Phone">
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label>Registraion Type</label>
                  <select class="form-control" formControlName="registerType">
                    <option value="">- Select Registration Type -</option>
                    <option value="4">All </option>
                    <option value="1">Customer </option>
                    <option value="3">Restaurant </option>
                    <option value="2">Driver </option>
                    <option value="5">Retailer </option>
                    <option value="6">Company </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label>Registered Date Range</label>
                  <input #datePicker1 type="text" name="daterangeInput" class="form-control" daterangepicker placeholder='Select date range'
                    [options]="options" (selected)="selectedDate($event)" (applyDaterangepicker)="applySelectedDate($event.picker)" 
                    (cancelDaterangepicker)="calendarCanceled()" />
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-3 offset-md-9">
                <button class="btn btn-default float-right " 
                  (click)="resetFilter()">Reset</button>
                <button class="btn btn-primary float-right mr-2 " [disabled]="isBtnDisabled()" (click)="applyFilter()">
                  Apply Now</button>



              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Content Row -->

  <div class="row">

    <!-- Area Chart -->
    <div class="col-xl-12 col-lg-12">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 align-items-center">
          <div class="row align-items-center">
            <div class="col-lg-9 col-md-6 col-sm-12 text-left">
              <h6 class="m-0 font-weight-bold text-primary">All Users</h6>
            </div>
          </div>
        </div>
        <!-- Card Body -->
        <div class="card-body" *ngIf="
        (usersData !== undefined &&
          usersData !== null &&
          usersData.length > 0) ||
        isLoading
      ">
          <!-- START TABLE DATA -->
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>E-mail</th>
                  <th>Contact No.</th>
                  <th>Register Type</th>
                  <th>Registered Since</th>
                  <th>Make Retailer</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="t-loading" *ngIf="isLoading">
                  <td colspan="6" class="text-center">
                    <div class="spinner-grow" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
                <tr *ngFor="let item of usersData;let i=index;">
                  <td>{{ (pageNumber - 1) * pageSize + i + 1 }}</td>
                  <td>
                    {{ item.firstName === undefined && item.lastName === undefined
                    ? "N/A"
                    : item.firstName +' ' + item.lastName }}
                  </td>
                  <td>
                    {{ item.email === undefined
                    ? "N/A"
                    : item.email }}
                  </td>
                  <td>{{item.countryCode}} {{item.phone}}</td>
                  <td>
                    <span>Customer</span><span *ngIf="item.isCompany">, Company</span><span *ngIf="item.isDriver">, Driver</span>
                    <span *ngIf="item.isVendor">, Restaurant
                    </span>
                  </td>
                  <td>{{ item.createdAt | date: "medium" }}</td>
                  <td>
                    <div class="form-check">
                      <input class="form-check-input position-static" type="checkbox" id="blankCheckbox" value="option1" (change)="makeRetailer(item.id)" [checked]="item.isRetailer" aria-label="...">
                    </div>
                  </td>
                  <td>
                    <a routerLink="/users/user-detail" routerLinkActive="active"
                      [queryParams]="{id: item.id,pageNo:selectedPage}" href="javascript:void(0);"
                      class="btn btn-primary btn-sm"><i class="fas fa-eye"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- END TABLE DATA -->
          <div class="row" *ngIf="usersData.length > 0">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info pt11px" id="dataTable_info" role="status" aria-live="polite">
                Showing {{ (selectedPage - 1) * pageSize + 1 }} to
                {{ (selectedPage - 1) * pageSize + usersData.length }} of
                {{ totalLength }} entries
              </div>
            </div>
            <div class="col-sm-12 col-md-7">
              <div class="dataTables_paginate float-right paging_simple_numbers" id="dataTable_paginate">
                <ngb-pagination [maxSize]="5" [rotate]="true" [(page)]="finalPage" [pageSize]="pageSize"
                  [collectionSize]="totalLength" (pageChange)="setPaginationPage($event)">
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="
          (usersData === undefined || usersData.length === 0) &&
          !isLoading
        ">
          <div class="no-image-area">
            <p class="title">No Users Found</p>

          </div>
        </div>
      </div>
    </div>

  </div>

</div>