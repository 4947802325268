import { Component, Input, OnInit } from "@angular/core";
import { LocalStorageService } from "src/app/shared/local-storage.service";
import { IsCompany, Roles } from "../../../shared/enum";
import { SidebarService } from "../../../services/sidebar/sidebar.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  userRole: any;
  username: any;
  roleEnum = Roles;
  isCompanyEnum = IsCompany;
  user: any;

  constructor(
    private localStroageService: LocalStorageService,
    public sidebarService: SidebarService
  ) {}

  ngOnInit() {
    this.userRole = this.localStroageService.getObjectsValueByKeyNameOrFullInfo(
      "userInfo",
      "role"
    );
    this.user = this.localStroageService.getObjectsValueByKeyNameOrFullInfo("userInfo");

    this.username = this.localStroageService.getObjectsValueByKeyNameOrFullInfo(
      "userInfo",
      "fullName"
    );
  }
}
