<div class="container">

  <!-- Outer Row -->
  <div class="row justify-content-center">

    <div class="col-xl-10 col-lg-12 col-md-9">

      <div class="card o-hidden border-0 shadow-lg my-5">
        <div class="card-body p-0">
          <!-- Nested Row within Card Body -->
          <div class="row">
            <div class="col-lg-6 d-none d-lg-block bg-password-image"></div>
            <div class="col-lg-6">
              <div class="p-5">
                <div class="text-center">
                  <h1 class="h4 text-gray-900 mb-2">Forgot Your Password?</h1>
                  <p class="mb-3">Just enter your email address below and we'll send you a link to reset your password!</p>
                </div>
                <form class="user" [formGroup]="forgetpasswordForm"
                (keyup.enter)="forgetPassword()">
              
                
                  <div class="form-group">
                    <input type="email" formControlName="email" class="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address...">
                    <div
                    *ngIf="submitted && forgetF.email.errors"
                    class="text-danger"
                  >
                    <div *ngIf="forgetF.email.errors.required">
                      Email is required
                    </div>
                    <div *ngIf="forgetF.email.errors.email">
                      Email must be a valid email address
                    </div>
                  </div>
                  </div>
                  <a
                    href="javascript:void(0);"
                    (click)="forgetPassword()"
                    *ngIf="!isApiWorked"
                    [ngClass]="{
                      disabled: isApiWorked || forgetpasswordForm.invalid
                    }"
                    class="btn btn-primary btn-user btn-block"
                  >
                    Reset Password
                  </a>
                  <a
                  href="javascript:void(0);"
                  (click)="forgetPassword()"
                  *ngIf="isApiWorked"
                  [ngClass]="{
                    disabled: isApiWorked || forgetpasswordForm.invalid
                  }"
                  class="btn btn-primary btn-user btn-block"
                >
                  loading... Reset Password
                </a>
                <div class="alert alert-danger mt-2" *ngIf="errMsg" role="alert">
                  {{errMsg}}
                </div>
                <div class="alert alert-success" role="alert" *ngIf="responseMsg">
                  <p class="mb-3">{{ responseMsg }}</p>
                </div>
                </form>
                <hr>
                <div class="text-center">
                  <a class="small" routerLink="/login">Already have an account? Login!</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>