import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Daterangepicker } from 'ng2-daterangepicker';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserComponent } from './user.component';
import { UserRoutes } from './user.routing';
@NgModule({
  imports: [
    CommonModule,
    UserRoutes,
    ReactiveFormsModule,
    Daterangepicker,
    FormsModule,
    NgbModule,

  ],
  declarations: [UserComponent, UserDetailComponent,]
})
export class UserModule { }
