import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { HomeRoutes } from './home.routing';
import { ChartsModule } from 'ng2-charts';
import { ThousandSuffixesPipe } from 'src/app/services/Thousand-suffixes.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from "../../../shared/shared.module";
@NgModule({
  imports: [
    CommonModule,
    HomeRoutes,
    ChartsModule,
    NgbModule,
    SharedModule
  ],
  declarations: [HomeComponent]
})
export class HomeModule { }
