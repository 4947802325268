import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { LocalStorageService } from "../../shared/local-storage.service";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  isProfileCompleted: boolean = true;
  constructor(private localStorageService: LocalStorageService) {
    let localPRofileCompleted =
    JSON.parse(localStorage.getItem("isProfileCompleted"));
    
    if(localPRofileCompleted == null){
        this.isProfileCompleted = true;
    } else {
      this.isProfileCompleted = localPRofileCompleted;
    }
  }


}
