<div class="container-fluid">

  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
  </div>

  <!-- Content Row -->
  <div class="row">

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-12 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Total Customers</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                {{dashboardData===undefined?0:dashboardData?.userCount}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-users fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-12 mb-4">
      <div class="card border-left-success shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Total Companies</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                {{dashboardData===undefined?0:dashboardData?.companyCount}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-fw fa-building fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-12 mb-4">
      <div class="card border-left-warning shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">Total Drivers</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                {{dashboardData===undefined?0:dashboardData?.driverCount}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-truck fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-3 col-md-12 mb-4">
      <div class="card border-left-info shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Total Cashback</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                <span ngbTooltip="{{cashbackAmount?.cashbackEarn | currency}}">
                  {{cashbackAmount===undefined?0:cashbackAmount?.cashbackEarn | thousandSuff: 2 }}</span>
              </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-money-bill-alt fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- Content Row -->
  <div class="row">

    <!-- Restaurant Chart -->

    <!-- <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12">
      <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">Restaurant Requests</h6>
          <span class="pull-right">
            <div class="btn-group btn-group-xs" role="group" aria-label="...">

              <button type="button" class="btn btn-sm btn-primary" (click)="getPriviousStartAndEndDate()"><i
                  class="fas fa-angle-left"></i></button>
              <button type="button" class="btn btn-sm btn-default" disabled>{{date}}</button>
              <button type="button" class="btn btn-sm btn-primary" [disabled]="nextBtnDisable"
                style="margin-right: 15px;" (click)="getNextStartAndEndDate()"><i
                  class="fas fa-angle-right "></i></button>

            </div>
          </span>
        </div>
        <div class="card-body">
          <div class="chart-area">
            <div class="chartjs-size-monitor">
              <div class="chartjs-size-monitor-expand">
                <div class=""></div>
              </div>
              <div class="chartjs-size-monitor-shrink">
                <div class=""></div>
              </div>
            </div>
            <canvas baseChart width="1458" height="640" [datasets]="lineChartData" [labels]="lineChartLabels"
              [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
              [chartType]="lineChartType" [plugins]="lineChartPlugins">
            </canvas>

          </div>
        </div>
      </div>
    </div> -->

    <!-- END Restaurant Chart -->


    <!-- Driver Chart -->

    <div class="col-lg-6 col-12">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">Total Users</h6>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <div class="chart-pie">
            <div class="chartjs-size-monitor">
              <div class="chartjs-size-monitor-expand">
                <div class=""></div>
              </div>
              <div class="chartjs-size-monitor-shrink">
                <div class=""></div>
              </div>
            </div>
            <canvas baseChart class="pie" [data]="Data"  [labels]="Labels" [colors]="Colors"
            [options]="pieChartOptions" [chartType]="pieChartType">
            </canvas>
          </div>
          <!-- <div class="mt-4 text-center small">
            <span class="mr-2">
              <i class="fas fa-circle text-primary"></i> Customers
            </span>
            <span class="mr-2">
              <i class="fas fa-circle text-success"></i> Company
            </span>
            <span class="mr-2">
              <i class="fas fa-circle text-warning"></i> Drivers
            </span>
          </div> -->
        </div>
      </div>
    </div>


    <!-- END Driver Chart -->

  </div>

</div>