import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantReportService {

  constructor(private apiService: ApiService,  private http: HttpClient) { }

  getAllRestaurant(year = "", month = "", quarterType = '', isPaid = '', pageNo = "", pageSize = "",  isPagination = 1 ) {
    const path = `/restaurant/report?year=${year}&month=${month}&quarterType=${quarterType}&isPaid=${isPaid}&isPagination=${isPagination}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.apiService.getRequest(path, true);
  }

  getData(year = "", month = "", quarterType = '', isPaid = '') {
    const path = `/restaurant/report/download?year=${year}&month=${month}&quarterType=${quarterType}&isPaid=${isPaid}`;
    return this.apiService.getRequest(path, true);
  }

  adminPay(orderId = '') {
    const path = `/admin/pay`;
    const obj = {
      orderIds : orderId
    };
    return this.apiService.postRequest(path, obj ,true);    
  }
}
