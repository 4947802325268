import { Injectable, ErrorHandler, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { LocalStorageService } from "./local-storage.service";
@Injectable({
  providedIn: "root",
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private injector: Injector, private _localStorageService: LocalStorageService) { }
  handleError(error: any) {
    if (error instanceof HttpErrorResponse) {
      const router = this.injector.get(Router);
      // const toastrService = this.injector.get(ToastrService);
      const isDeleteAccount = this._localStorageService.getObjectsValueByKeyNameOrFullInfo(
        "isDeleteAccount"
      );

      const toastrService = this.injector.get<any>(ToastrService);
      if (error.status === 401) {
        this.toastrService.error(
          "Session Expire",
          "",
          {
            closeButton: true,
            timeOut: 5000,
            onActivateTick: true
          }
        );        
        localStorage.clear();
        let bodyclass = document.getElementsByTagName("body");
        bodyclass[0].classList.add("bg-gradient-primary");

        if(isDeleteAccount){
          router.navigateByUrl("/user/login");
        } else {
          setTimeout(() => {
            location.reload();
          }, 500);
        }
        // router.navigateByUrl("/login");
      }
      if (error.status === 404) {
        if(!isDeleteAccount){
        router.navigateByUrl("/not-found");
        }
      }

      if (error.status)
        console.error("Backend returned status code: ", error.status);

      console.error("Response body:", error.message);
    } else {
      console.error("An error occurred:", error.message);
    }
  }

  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

}
