<ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  id="accordionSidebar" #navUl>

  <!-- Sidebar - Brand -->
  <a class="sidebar-brand d-flex align-items-center justify-content-center"
    href="index.html">
    <!-- <div class="sidebar-brand-icon rotate-n-15">
      <i class="fas fa-laugh-wink"></i>
    </div> -->
    <div class="sidebar-brand-text mx-3">Ottlo</div>
  </a>

  <!-- Divider -->
  <hr class="sidebar-divider my-0">
  <ng-container *ngIf="userRole.slug === roleEnum.SUPER_ADMIN">
    <!-- Nav Item - Dashboard -->
    <li class="nav-item " [ngClass]="{ active: isActive('home') }">
      <a class="nav-link" routerLink="/home"
        routerLinkActive="active">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>Dashboard</span></a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">

    <!-- Heading -->
    <div class="sidebar-heading">
      App Management
    </div>

    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item" [ngClass]="{ active: isActive('users') }">
      <a class="nav-link" routerLink="/users"
        routerLinkActive="active">
        <i class="fas fa-fw fa-users"></i>
        <span>Users</span></a>
    </li>
    <li class="nav-item" [ngClass]="{ active: isActive('companies') }">
      <a class="nav-link" routerLink="/companies"
        routerLinkActive="active">
        <i class="fas fa-fw fa-building"></i>
        <span>Company</span></a>
    </li>
    <!-- Nav Item - Utilities Collapse Menu -->
    <li class="nav-item"
      [ngClass]="{ active: isActive('driver-request')  || isActive('restaurant-request')    }">
      <a class="nav-link collapsed" href="javascript:void(0);"
        data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
        aria-controls="collapseTwo">
        <i class="fas fa-fw fa-user-plus"></i>
        <span>Requests</span>
      </a>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
        data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">All Requests</h6>
          <a class="collapse-item" href="javascript:void(0);"
            routerLink="/restaurant-request"
            routerLinkActive="active">Restaurant Requests</a>
          <a class="collapse-item" href="javascript:void(0);"
            routerLink="/driver-request"
            routerLinkActive="active">Driver Requests</a>
        </div>
      </div>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">

    <!-- Heading -->
    <div class="sidebar-heading">
      Addons
    </div>

    <!-- Nav Item - Utilities Collapse Menu -->
    <li class="nav-item"
      [ngClass]="{ active: isActive('restaurant-reports') || isActive('driver-reports')  || isActive('company-reports')   }">
      <a class="nav-link collapsed" href="#" data-toggle="collapse"
        data-target="#collapsethree" aria-expanded="true"
        aria-controls="collapseTwo">
        <i class="fas fa-fw fa-clipboard-list"></i>
        <span>Reports</span>
      </a>
      <div id="collapsethree" class="collapse" aria-labelledby="headingTwo"
        data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">All Reports</h6>
          <a class="collapse-item" href="javascript:void(0);"
            routerLink="/restaurant-reports"
            routerLinkActive="active">Restaurant Reports</a>
          <a class="collapse-item" href="javascript:void(0);"
            routerLink="/driver-reports"
            routerLinkActive="active">Driver Reports</a>
          <a class="collapse-item" href="javascript:void(0);"
            routerLink="/company-reports"
            routerLinkActive="active">Company Reports</a>
        </div>
      </div>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block">
  </ng-container>

  <ng-container
    *ngIf="userRole.slug === roleEnum.USER && user.isCompany === isCompanyEnum.APPROVED">
    <!-- Company -->
    <!-- <li class="nav-item" [ngClass]="{ active: isActive('company/profile-setup') }">
    <a class="nav-link"   routerLink="/company/profile-setup"
    routerLinkActive="active" >
      <i class="fas fa-fw fa-users"></i>
      <span>Profile Setup</span></a>
  </li> -->

    <li class="nav-item" [ngClass]="{ active: isActive('company/dashboard') }">
      <a class="nav-link" routerLink="/company/dashboard" [ngClass]="{
      disabled: !sidebarService.isProfileCompleted
    }"
        routerLinkActive="active">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>Dashboard</span></a>
    </li>

    <li class="nav-item" [ngClass]="{ active: isActive('company/drivers') }">
      <a class="nav-link" routerLink="/company/drivers" [ngClass]="{
      disabled: !sidebarService.isProfileCompleted
    }"
        routerLinkActive="active">
        <i class="fas fa-fw fa-users"></i>
        <span>Drivers</span></a>
    </li>
    <li class="nav-item" [ngClass]="{ active: isActive('company/rate-info') }">
      <a class="nav-link" routerLink="/company/rate-info" [ngClass]="{
      disabled: !sidebarService.isProfileCompleted
    }"
        routerLinkActive="active">
        <i class="fas fa-fw fa-users"></i>
        <span>Rate Info</span></a>
    </li>

    <li class="nav-item"
      [ngClass]="{ active: isActive('company/driver-reports') }">
      <a class="nav-link" routerLink="/company/driver-reports" [ngClass]="{
      disabled: !sidebarService.isProfileCompleted
    }"
        routerLinkActive="active">
        <i class="fas fa-fw fa-clipboard-list"></i>
        <span>Driver Reports</span></a>
    </li>
    <!-- Company End-->
    <hr class="sidebar-divider d-none d-md-block">
  </ng-container>
  <!-- Sidebar Toggler (Sidebar) -->
  <div class="text-center d-none d-md-inline">
    <button (click)="sidebarToggled()" class="rounded-circle border-0"
      id="sidebarToggle"></button>
  </div>

</ul>