import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestaurantRequestService } from 'src/app/services/restaurant-request/restaurant-request.service';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-restaurant-detail',
  templateUrl: './restaurant-detail.component.html',
  styleUrls: ['./restaurant-detail.component.scss']
})
export class RestaurantDetailComponent implements OnInit {
  resturantRequestDetails: any;
  isLoading: boolean = false;
  errMsg: any;
  finalData: any = [];
  requestId: string;
  @ViewChild("aclsBtn") acancelBtn;
  @ViewChild("rclsBtn") rcancelBtn;
  statusId: any;
  selectedPages: number;
  resturantBankRequestDetails: any;

  pageSize: any;
  selectedPage: any = 1;
  finalPage = 1;
  totalLength: number = 0;
  pageNumber: number = 1;
  collectionSize: number;
  resturantsEmployeeData: any = [];
  constructor(public _commonService: CommonService, private route: ActivatedRoute, private _resturantService: RestaurantRequestService, private router: Router, private common: CommonService) { }

  ngOnInit() {
    this.requestId = this.route.snapshot.queryParamMap.get("id");
    const pageSize = this.route.snapshot.queryParamMap.get("pageNo");
    this.selectedPages = pageSize === null ? 1 : parseInt(pageSize);
    this.pageSize = this.common.PAGINATION.default_page_size;
    this.getResturantDetails();
    this.getRestaurantBankdetails();
    this.getResturantsAllEmployeeData();
  }
  getRestaurantBankdetails() {
    this._resturantService
      .resturantBankDetails(this.requestId)
      .subscribe(
        (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== ""
          ) {
            this.resturantBankRequestDetails = res.response;
          }
        }, err => {
          this.isLoading = false;
          throw err;
        })

  }
  back() {
    this.selectedPages === 1 ? this.router.navigateByUrl("/restaurant-request") : this.router.navigate(["/restaurant-request"], { queryParams: { pageNo: this.selectedPages } });
  }

  getResturantDetails() {
    this._resturantService
      .resturantDetails(this.requestId)
      .subscribe(
        (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== ""
          ) {
            this.resturantRequestDetails = res.response;
            this.statusId = res.response.id;
          }
        }, err => {
          this.isLoading = false;
          throw err;
        })
  }

  returantRequestIsAcceptOrReject(status) {
    const body = {
      vendorId: this.statusId,
      status: status
    };
    this.isLoading = true;
    this._resturantService
      .resturantStatus(body)
      .subscribe(
        (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== ""
          ) {
            this.getResturantDetails();
            this.acancelBtn.nativeElement.click();
            this.rcancelBtn.nativeElement.click();
          } else {
            this.errMsg = res.errorMsg;
          }
          this.isLoading = false;


        },
        (err) => {
          this.isLoading = false;
          throw err;
        }
      );

  }

  getResturantsAllEmployeeData() {
    this.isLoading = true;
    this._resturantService
      .getAllResturantsEmployees(this.selectedPage, this.pageSize, this.requestId)
      .subscribe(
        (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== "" &&
            Array.isArray(res.response.list) &&
            res.response.list.length > 0
          ) {
            this.totalLength = res.response.total;
            this.collectionSize = res.response.list.length;

            this.resturantsEmployeeData = res.response.list;
            this.finalPage = this.selectedPage;
          }
          else {
            this.resturantsEmployeeData = [];
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;

          throw err;
        }
      );
  }
  onPageSizeChange(event) {
    this.pageSize = event.target.value;
    this.getResturantsAllEmployeeData();
  }
  setPaginationPage(event) {
    this.selectedPage = event;
    this.pageNumber = event;
    this.getRestaurantBankdetails();
  }
  acceptedEmployee(id) {
    this._resturantService
      .acceptEmployee(id)
      .subscribe(
        (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== ""
          ) {
            this.getResturantsAllEmployeeData();
          }
        }, err => {
          this.isLoading = false;
          throw err;
        })

  }
  rejectedEmployee(id) {
    this._resturantService
      .rejectEmployee(id)
      .subscribe(
        (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== ""
          ) {
            this.getResturantsAllEmployeeData();
          }
        }, err => {
          this.isLoading = false;
          throw err;
        })

  }

}
