<div class="container-fluid">
  <!-- Content Row -->

  <div class="row">

    <div class="col-md-6">
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Driver Details</h1>
      </div>
    </div>
    <div class="col-md-6">
      <div class="text-right mb-4">
        <div class="action-btns">
          <a *ngIf="driverRequestDetails?.status===0" href="javascript:void(0);"
            class="btn pull-right btn-sm btn-primary btn-icon-split mr-1" data-toggle="modal"
            data-target="#exampleModal">
            <span class="icon text-white-50">
              <i class="fas fa-check"></i>
            </span>
            <span class="text">Accept</span>
          </a>
          <a *ngIf="driverRequestDetails?.status===0" data-toggle="modal" data-target="#exampleModal1"
            href="javascript:void(0)" class="btn pull-right btn-sm btn-danger btn-icon-split mr-1">
            <span class="icon text-white-50">
              <i class="fas fa-times"></i>
            </span>
            <span class="text">Reject</span>
          </a>
          <a href="javascript:void(0);" (click)="back()" routerLinkActive="active"
            class="btn pull-right btn-sm btn-primary btn-icon-split">
            <span class="icon text-white-50">
              <i class="fas fa-angle-left"></i>
            </span>
            <span class="text">Back</span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Vehicle & Purchase Information -->
  <div class="row">
    <div class="col-md-12">
      <div class="card shadow ">
        <div class="card-header py-3">
          <div class="row align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-12 text-left">
              <h6 class="m-0 font-weight-bold text-primary">Basic Information</h6>
            </div>
            <!-- <div class="col-sm-6 col-md-6 col-lg-6 text-right">
              <a href="javascript:void(0)" class="btn btn-sm btn-primary btn-icon-split" data-toggle="modal"
                data-target=".userinformation">
                <span class="icon text-white-50">
                  <i class="fas fa-edit"></i>
                </span>
                <span class="text">Edit</span>
              </a>
            </div> -->
          </div>
        </div>
        <div class="card-body" *ngIf="
        (driverRequestDetails !== undefined && driverRequestDetails !== null) ||
        isLoading
      ">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="mb-3">
                <p class="mb-0"><small>Driver Image:</small> </p>
                <img
                  src="{{driverRequestDetails!==undefined && driverRequestDetails.photo!==undefined?driverRequestDetails.photo.location:_commonService.driverImageSrc}}"
                  alt="..." class="img-thumbnail img-res">
              </div>
            </div>
            <div class="col-sm-10 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>First Name:</small> </p>
                    <span class="text-gray-900">{{driverRequestDetails.user.firstName}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Last Name:</small> </p>
                    <span class="text-gray-900">{{driverRequestDetails.user.lastName}}</span>
                  </div>
                </div>
                <!-- <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>E-mail:</small> </p>
                    <span class="text-gray-900">
                      {{
                        driverRequestDetails === undefined || driverRequestDetails.user === undefined
                          ? "N/A"
                          : driverRequestDetails.user.email
                      }}
                    </span>
                  </div>
                </div> -->
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small> Contact No.:</small> </p>
                    <span class="text-gray-900">{{driverRequestDetails?.countryCode}}
                      {{driverRequestDetails?.phone}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Registered Type:</small> </p>
                    <span class="text-gray-900">Driver</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Address:</small> </p>
                    <span class="text-gray-900">{{driverRequestDetails?.address?.address}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Vehicle Name:</small> </p>
                    <span class="text-gray-900">{{driverRequestDetails?.vehicle?.name }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Vehicle Make:</small> </p>
                    <span class="text-gray-900">{{driverRequestDetails?.vehicleMake }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Vehicle Model:</small> </p>
                    <span class="text-gray-900">{{driverRequestDetails?.vehicleModel }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Vehicle Color:</small> </p>
                    <span class="text-gray-900">{{driverRequestDetails?.vehicleColor }}</span>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Food Delivery:</small> </p>
                    <span class="text-gray-900">{{ driverRequestDetails?.isFoodDelivery === 1 ? "Yes" : "No" }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Other Delivery:</small> </p>
                    <span class="text-gray-900">{{ driverRequestDetails?.isMarketplaceDelivery === 1 ? "Yes" : "No"
                      }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Bulk Item Delivery:</small> </p>
                    <span class="text-gray-900">
                      {{ driverRequestDetails?.isBulkItemDelivery === 1 ? "Yes" : "No" }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Food Charge:</small> </p>
                    <span class="text-gray-900">{{driverRequestDetails?.foodCharge | number: '.2' | currency}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Other Charge:</small> </p>
                    <span class="text-gray-900">{{driverRequestDetails?.marketplaceCharge | number: '.2' |
                      currency}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4"
                  *ngIf="driverRequestDetails?.isBulkItemDelivery === 1">
                  <div class="mb-3">
                    <p class="mb-0"><small>Bulk Item Weight:</small> </p>
                    <span class="text-gray-900" *ngIf="driverRequestDetails?.bulkItemWeight === 1">50
                      pounds or less</span>
                    <span class="text-gray-900" *ngIf="driverRequestDetails?.bulkItemWeight === 2">91-99
                      pounds</span>
                    <span class="text-gray-900" *ngIf="driverRequestDetails?.bulkItemWeight === 3">100+
                      pounds</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4" *ngIf="driverRequestDetails?.areaServe">
                  <div class="mb-3">
                    <p class="mb-0"><small>Area Serve:</small> </p>
                    <span class="text-gray-900">{{driverRequestDetails?.areaServe.name}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Licence:</small> </p>
                    <span class="text-gray-900">{{driverRequestDetails?.licence}}</span>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Registered Since:</small> </p>
                    <span class="text-gray-900">{{ driverRequestDetails.createdAt | date: "medium" }}</span>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="
          (driverRequestDetails === undefined || driverRequestDetails === null) &&
          !isLoading
        ">
          <div class="no-image-area">
            <p class="title">No Resturant Details Information Found</p>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="card shadow mb-4 brt-rl">
    <div class="card-header py-3">
      <div class="row align-items-center">
        <div class="col-sm-6 col-md-6 col-lg-6 text-left">
          <h6 class="m-0 font-weight-bold text-primary">Bank Information</h6>
        </div>
        <!-- <div class="col-sm-6 col-md-6 col-lg-6 text-right">
          <a href="javascript:void(0)" class="btn btn-sm btn-primary btn-icon-split" data-toggle="modal"
            data-target=".restaurantinformation">
            <span class="icon text-white-50">
              <i class="fas fa-edit"></i>
            </span>
            <span class="text">Edit</span>
          </a>
        </div> -->
      </div>
    </div>
    <div class="card-body" *ngIf="
    (driverBankRequestDetails !== undefined && driverBankRequestDetails !== null) ||
    isLoading
  ">
      <div class="row">
        <div class="col-sm-10 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <div class="mb-3">
                <p class="mb-0"><small>Bank Name:</small> </p>
                <span class="text-gray-900">{{driverBankRequestDetails?.bankName}}</span>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <div class="mb-3">
                <p class="mb-0"><small>Account No.:</small> </p>
                <span class="text-gray-900">{{driverBankRequestDetails?.accountNumber}}</span>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <div class="mb-3">
                <p class="mb-0"><small>Branch Name:</small> </p>
                <span class="text-gray-900">{{driverBankRequestDetails?.branchName}}</span>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <div class="mb-3">
                <p class="mb-0"><small>IFSC Code:</small> </p>
                <span class="text-gray-900">{{driverBankRequestDetails?.ifscCode}}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="card-body" *ngIf="
    (driverBankRequestDetails === undefined || driverBankRequestDetails === null) &&
    !isLoading
  ">
      <div class="no-image-area">
        <p class="title">No Bank Information Found</p>
      </div>
    </div>
  </div>

  <!-- Bank Information -->

</div>


<!-- Accept Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Accept Status</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">Are you sure you want to accept this request ?</div>
      <div class="modal-footer">
        <button class="btn btn-secondary" #aclsBtn type="button" data-dismiss="modal">Cancel</button>
        <button class="btn btn-primary" (click)="driverRequestIsAcceptOrReject(2)">Ok</button>
      </div>
    </div>
  </div>
</div>

<!-- Reject Modal -->
<div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Reject Status</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">Are you sure you want to reject this request ? </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" #rclsBtn type="button" data-dismiss="modal">Cancel</button>
        <button class="btn btn-primary" (click)="driverRequestIsAcceptOrReject(3)">Ok</button>
      </div>
    </div>
  </div>
</div>