<div class="container" *ngIf="isAdminUser">
  <!-- Outer Row -->
  <div class="row justify-content-center">
    <div class="col-xl-10 col-lg-12 col-md-9">
      <div class="card o-hidden border-0 shadow-lg my-5">
        <div class="card-body p-0">
          <!-- Nested Row within Card Body -->
          <div class="row">
            <div class="col-lg-6 d-none d-lg-block bg-password-image"></div>
            <div class="col-lg-6">
              <div class="p-5">
                <div class="text-center">
                  <h1 class="h4 text-gray-900 mb-2">Reset Password</h1>
                  <p *ngIf="isTokenValid" class="mb-4">
                    Both Passwords should be match
                  </p>
                </div>
                <form
                  class="user"
                  [formGroup]="resetPasswordForm"
                  *ngIf="isTokenValid"
                  (keyup.enter)="resetPassword()"
                >
                  <div class="text-center text-danger" *ngIf="errMsg">
                    <p class="mb-3">{{ errMsg }}</p>
                  </div>
                  <div class="form-group">
                    <input
                    type="password"
                    class="form-control form-control-user"
                    id="exampleInputEmail"
                    formControlName="newPassword"
                    aria-describedby="emailHelp"
                    placeholder="New Password"
                  />
                  <div
                    *ngIf="submitted && resetPasswordF.newPassword.errors"
                    class="text-danger"
                  >
                    <div *ngIf="resetPasswordF.newPassword.errors.required">
                      Password is required
                    </div>
                    <div *ngIf="resetPasswordF.newPassword.errors.minlength">
                      Password must be at least 6 characters
                    </div>
                  </div>
                </div>
                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control form-control-user"
                      id="exampleInputEmail"
                      formControlName="confirmPassword"
                      aria-describedby="emailHelp"
                      placeholder="Confirm Password"
                    />
                    <div
                      *ngIf="submitted && resetPasswordF.confirmPassword.errors"
                      class="text-danger"
                    >
                      <div
                        *ngIf="resetPasswordF.confirmPassword.errors.required"
                      >
                        Confirm password is required
                      </div>
                      <div
                        *ngIf="resetPasswordF.confirmPassword.errors.minlength"
                      >
                        Confirm password must be at least 6 characters
                      </div>
                    </div>
                    <div
                      *ngIf="
                        resetPasswordForm.errors?.passwordshouldMatch &&
                        submitted &&
                        resetPasswordForm.invalid &&
                        !resetPasswordF.confirmPassword.errors
                      "
                      class="text-danger"
                    >
                      Password and confirm password does not match.
                    </div>
                  </div>
                  <a
                    (click)="resetPassword()"
                    href="javascript:void(0);"
                    class="btn btn-primary btn-user btn-block"
                    *ngIf="isApiWorked"
                  >
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    loading... Reset Password
                  </a>
                  <ng-container #other>
                    <a  *ngIf="!isApiWorked"
                      (click)="resetPassword()"
                      href="javascript:void(0);"
                      class="btn btn-primary btn-user btn-block"
                    >
                      Reset Password
                    </a>
                  </ng-container>
                </form>
                <div class="text-center text-danger" *ngIf="!isTokenValid">
                  <p class="mb-3">{{ errMsg }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="content-box reset-box" *ngIf="!isAdminUser">
  <div class="container">
      <div class="content-box-inner">
          <div class="row justify-content-center">
              <div class="col-auto">
                  <div class="card o-hidden border-0 shadow-lg my-5">
                      <div class="card-body p-5">
                          <h1 class="h4 text-gray-900 mb-3">Reset Your Password</h1>
                          <div class="d-flex align-items-center mb-3">
                              <img class="img-fluid mr-2" src="./assets/img/images/check-mark-filled.svg"
                                  alt="Check mark filled">
                              <p class="text-success m-0">Your password has been reset successfully!!</p>
                          </div>
                          <p class="m-0 fw-lighter reset-text">Open your Ottlo app and Sign In.</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
