import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './modules/main/main.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';


const routes: Routes = [
  { path: "", loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  { path: "login", loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  {
    path: "home",
    component: MainComponent,
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuard],
  },
  { path: "company", loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule), canActivate: [AuthGuard] },
  { path: "user", loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule) },
  {
    path: "forget-password",
    loadChildren:
      () => import('./modules/forget-password/forget-password.module').then(m => m.ForgetPasswordModule),
  },
  {
    path: "reset-password/:token/:isAdmin",
    loadChildren:
      () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'not-found', component: PageNotFoundComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
