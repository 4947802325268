import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DriverReportsComponent } from './driver-reports.component';
import { DriverReportsRoutes } from './driver-reports.routing';

@NgModule({
  imports: [
    CommonModule,
    DriverReportsRoutes,
    NgbModule,
    ReactiveFormsModule

  ],
  declarations: [DriverReportsComponent]
})
export class DriverReportsModule { }
