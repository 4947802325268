import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor() { }

  setData(userInfo: any, devicveInfo: any) {
    const encryptUserInfo = CryptoJS.AES.encrypt(
      userInfo.trim(),
      environment.localStoragePassword.trim()
    ).toString();
    const encryptDeviceInfo = CryptoJS.AES.encrypt(
      devicveInfo.trim(),
      environment.localStoragePassword.trim()
    ).toString();
    localStorage.setItem("userInfo", encryptUserInfo);
    localStorage.setItem("deviceInfo", encryptDeviceInfo);
  }

  getObjectsValueByKeyNameOrFullInfo(objName, keyName = "") {
    const objInfo = localStorage.getItem(objName);
    if (objInfo) {
      const decrypteInfo = CryptoJS.AES.decrypt(
        objInfo.trim(),
        environment.localStoragePassword.trim()
      ).toString(CryptoJS.enc.Utf8);
      const finalInfo = JSON.parse(decrypteInfo);
      return keyName === "" ? finalInfo : finalInfo[keyName];
    } else {
      return null;
    }
  }

  setItem(key: string, value: any){
    const encryptdata = CryptoJS.AES.encrypt(
      value.trim(),
      environment.localStoragePassword.trim()
    ).toString();
    localStorage.setItem(key, encryptdata);
  }
}
