import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantRequestService {

  constructor(private apiService: ApiService) { }

  getAllResturants(pageNo = "", pageSize = "", status = "", name = "", startDate = '', endDate = '', isPagination = 1) {
    const path = `/restaurant?isPagination=${isPagination}&pageNo=${pageNo}&pageSize=${pageSize}&status=${status}&keyword=${name}&startDate=${startDate}&endDate=${endDate}`;
    return this.apiService.getRequest(path, true);
  }
  resturantStatus(data: any) {
    const path = `/restaurant/status`;
    return this.apiService.putRequest(path, data);
  }
  resturantDetails(id: any) {
    const path = `/restaurant/detail/${id}`;
    return this.apiService.getRequest(path, true);
  }
  resturantBankDetails(id: any) {
    const path = `/restaurant/bankDetail/${id}`;
    return this.apiService.getRequest(path, true);
  }
  getAllResturantsEmployees(pageNo = "", pageSize = "", userId = "", isPagination = 1) {
    const path = `/restaurant/employee?isPagination=${isPagination}&pageNo=${pageNo}&pageSize=${pageSize}&userId=${userId}`;
    return this.apiService.getRequest(path, true);
  }
  acceptEmployee(id: any) {
    const path = `/restaurant/acceptEmployee/${id}`;
    return this.apiService.getRequest(path, true);
  }
  rejectEmployee(id: any) {
    const path = `/restaurant/rejectEmployee/${id}`;
    return this.apiService.getRequest(path, true);
  }

}
