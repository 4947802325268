import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private apiService: ApiService) { }

  getAllUsers(pageNo = "", pageSize = "", name = "", registerType = 1, startDate = '', endDate = '', isPagination = 1) {
    const path = `/customers?isPagination=${isPagination}&pageNo=${pageNo}&pageSize=${pageSize}&keyword=${name}&registerType=${registerType}&startDate=${startDate}&endDate=${endDate}`;
    return this.apiService.getRequest(path, true);
  }
  // &status=${status}
  userDetails(id: any) {
    const path = `/customer/detail/${id}`;
    return this.apiService.getRequest(path, true);
  }

  makeRetailer(id: any){
    let data = {}
    const path = `/customers/${id}/makeRetailer`;
    return this.apiService.putRequest(path, data);
  }

  deleteUSer(){
    let data = {}
    const path = '/deleteUser'
    return this.apiService.postRequest(path, data, true);
  }

}
