import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // pie chart
  public Labels: string[] = ['Customers', 'Companies', 'Drivers'];
  public Data: any = [];
  public pieChartType: string = 'pie';
  public Colors: any =
    [
      {
        backgroundColor: [
          '#4e73df',
          '#1cc88a',
          '#f6c23e'
        ]
      }
    ];

  startDate: any;
  endDate: any;
  nextBtnDisable: boolean;
  date: any;

  //line charts
  public lineChartData: ChartDataSets[] = [{ data: [], label: '' },];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChartOptions: any = {
    responsive: true,
  };

  public pieChartOptions: any  = {
    responsive: true,
    maintainAspectRatio: false,
  };

  public lineChartColors: Color[] = [
    {
      borderColor: 'rgba(255,0,0,0.3)',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];

  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  isLoading: boolean;
  dashboardData: any;
  cashbackAmount: any;
  constructor(private _dashboardService: DashboardService) { }
  temp: any;
  ngOnInit() {
    this.getCurrentStartAndEndDate();
    this.getDashboardDataPointData();
    this.getRestaurantRequestst();
    this.getCashbackAmount();
    this.temp = 12000;
  }
  
  getDashboardDataPointData() {
    this.isLoading = true;
    this._dashboardService
      .dashBoardDataPoint()
      .subscribe(
        (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== ""
          ) {
            this.dashboardData = res.response;
            this.Data = [this.dashboardData.userCount, this.dashboardData.companyCount, this.dashboardData.driverCount]

          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        }
      );
  }

  getRestaurantRequestst() {

    this.startDate = this.startDate.getFullYear() + "-" + ((this.startDate.getMonth()) < 8 ? '0' : '') + (this.startDate.getMonth() + 1) + "-" + this.startDate.getDate();
    this.endDate = this.endDate.getFullYear() + "-" + ((this.endDate.getMonth()) < 8 ? '0' : '') + (this.endDate.getMonth() + 1) + "-" + this.endDate.getDate();
    this._dashboardService
      .getRestaurantRequestst(this.startDate, this.endDate)
      .subscribe(
        (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== ""
          ) {
            this.lineChartLabels = [];
            this.lineChartData = [];
            let chartData = [];
            for (let i = 0; i < res.response.length; i++) {
              this.lineChartLabels.push(res.response[i].label);
              chartData.push(res.response[i].total);
            }
            this.lineChartData = [
              { data: chartData, label: 'Series A' },
            ];
          }
        },
        (err) => {
          throw err;
        }
      );
  }

  getCurrentStartAndEndDate() {
    let today = new Date();
    this.date = today.getFullYear();
    this.endDate = this.setDateForDatePicker(31, 11, this.date);
    this.startDate = this.setDateForDatePicker(1, 0, this.date);

    if (this.endDate >= new Date()) {
      this.nextBtnDisable = true;
    } else {
      this.nextBtnDisable = false;
    }
  }

  getCashbackAmount() {
    this.isLoading = true;
    this._dashboardService
      .cashbackAmount()
      .subscribe(
        (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== ""
          ) {
            this.cashbackAmount = res.response;
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        }
      );
  }

  getPriviousStartAndEndDate() {
    this.date = this.date - 1;
    this.endDate = this.setDateForDatePicker(31, 11, this.date);
    this.startDate = this.setDateForDatePicker(1, 0, this.date);
    if (this.endDate >= new Date()) {
      this.nextBtnDisable = true;
    } else {
      this.nextBtnDisable = false;
    }
    this.getRestaurantRequestst();
  }

  getNextStartAndEndDate() {
    this.date = this.date + 1;
    this.endDate = this.setDateForDatePicker(31, 11, this.date);
    this.startDate = this.setDateForDatePicker(1, 0, this.date);

    if (this.endDate >= new Date()) {
      this.nextBtnDisable = true;
    } else {
      this.nextBtnDisable = false;
    }
    this.getRestaurantRequestst();
  }

  setDateForDatePicker(dd?: number, mm?: number, yyyy?: number): any {
    let today = new Date();
    dd = dd == undefined ? today.getDate() : dd;
    mm = mm == undefined ? today.getMonth() : mm;
    yyyy = yyyy == undefined ? today.getFullYear() : yyyy;
    return new Date(yyyy, mm, dd);
  }

}
