import { Routes, RouterModule } from '@angular/router';
import { RestaurantDetailComponent } from './restaurant-detail/restaurant-detail.component';
import { RestaurantRequestComponent } from './restaurant-request.component';

const routes: Routes = [
  { path: "", component: RestaurantRequestComponent },
  {
    path: "restaurant-detail",
    component: RestaurantDetailComponent,

  },
];

export const RestaurantRequestRoutes = RouterModule.forChild(routes);
