import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ResetPasswordComponent } from "./reset-password.component";
import { ResetPasswordRoutes } from "./reset-password.routing";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  imports: [
    CommonModule,
    ResetPasswordRoutes,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot()
  ],
  declarations: [ResetPasswordComponent],
})
export class ResetPasswordModule { }
