<div class="container-fluid">
  <!-- Content Row -->

  <div class="row">

    <div class="col-md-6">
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Request Details</h1>
      </div>
    </div>
    <div class="col-md-6">
      <div class="text-right mb-4">
        <div class="action-btns">
          <a *ngIf="resturantRequestDetails?.status===0" href="javascript:void(0);"
            class="btn pull-right btn-sm btn-primary btn-icon-split mr-1" data-toggle="modal"
            data-target="#exampleModal">
            <span class="icon text-white-50">
              <i class="fas fa-check"></i>
            </span>
            <span class="text">Accept</span>
          </a>
          <a data-toggle="modal" data-target="#exampleModal1" *ngIf="resturantRequestDetails?.status===0"
            href="javascript:void(0)" class="btn pull-right btn-sm btn-danger btn-icon-split mr-1">
            <span class="icon text-white-50">
              <i class="fas fa-times"></i>
            </span>
            <span class="text">Reject</span>
          </a>
          <a href="javascript:void(0);" (click)="back()" class="btn pull-right btn-sm btn-primary btn-icon-split">
            <span class="icon text-white-50">
              <i class="fas fa-angle-left"></i>
            </span>
            <span class="text">Back</span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Restaurant Information -->
  <div class="row">
    <div class="col-md-12">
      <div class="card shadow brb-rl">
        <div class="card-header py-3">
          <div class="row align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-12 text-left">
              <h6 class="m-0 font-weight-bold text-primary">Restaurant Information</h6>
            </div>
            <!-- <div class="col-sm-6 col-md-6 col-lg-6 text-right">
              <a href="javascript:void(0)" class="btn btn-sm btn-primary btn-icon-split" data-toggle="modal"
                data-target=".restaurantinformation">
                <span class="icon text-white-50">
                  <i class="fas fa-edit"></i>
                </span>
                <span class="text">Edit</span>
              </a>
            </div> -->
          </div>
        </div>
        <div class="card-body" *ngIf="
        (resturantRequestDetails !== undefined && resturantRequestDetails !== null) ||
        isLoading
      ">
          <div class="row">
            <div class="col-sm-10 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Restaurant Name:</small> </p>
                    <span class="text-gray-900">{{resturantRequestDetails?.name}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>E-mail:</small> </p>
                    <span class="text-gray-900">
                      {{
                      resturantRequestDetails === undefined || resturantRequestDetails.user.email === undefined ||
                      resturantRequestDetails.user === undefined
                      ? "N/A"
                      : resturantRequestDetails.user.email
                      }}
                    </span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Contact No.:</small> </p>
                    <span class="text-gray-900">
                      {{ resturantRequestDetails === undefined || resturantRequestDetails.phone === undefined
                      ? "N/A"
                      : resturantRequestDetails?.countryCode + ' '+ resturantRequestDetails?.phone
                      }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Registered Type:</small> </p>
                    <span class="text-gray-900">Restaurant</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Menu Card Link:</small> </p>
                    <span class="text-gray-900">{{
                      resturantRequestDetails === undefined || resturantRequestDetails.menuLink === undefined
                      ? "N/A"
                      : resturantRequestDetails.menuLink
                      }}
                      <a href="{{resturantRequestDetails?.menuLink}}"> <i class="fas fa-external-link-alt ml-1"></i></a>
                    </span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Register Date:</small> </p>
                    <span class="text-gray-900">{{ resturantRequestDetails?.createdAt | date: "medium" }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="mb-3">
                    <p class="mb-0"><small>Address:</small> </p>
                    <span class="text-gray-900">{{resturantRequestDetails?.address?.address}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="mb-3">
                    <p class="mb-0"><small>Restaurant Image:</small> </p>
                    <img
                      src="{{resturantRequestDetails!==undefined && !resturantRequestDetails.photo!==undefined ?resturantRequestDetails.photo.location:_commonService.restaurantImageSrc}}"
                      alt="..." class="img-thumbnail img-res">
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="
          (resturantRequestDetails === undefined || resturantRequestDetails === null) &&
          !isLoading
        ">
          <div class="no-image-area">
            <p class="title">No Resturant Details Information Found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Bank Information -->
  <div class="card shadow mb-4 brt-rl">
    <div class="card-header py-3">
      <div class="row align-items-center">
        <div class="col-sm-6 col-md-6 col-lg-6 text-left">
          <h6 class="m-0 font-weight-bold text-primary">Bank Information</h6>
        </div>
        <!-- <div class="col-sm-6 col-md-6 col-lg-6 text-right">
                <a href="javascript:void(0)" class="btn btn-sm btn-primary btn-icon-split" data-toggle="modal"
                  data-target=".restaurantinformation">
                  <span class="icon text-white-50">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span class="text">Edit</span>
                </a>
              </div> -->
      </div>
    </div>
    <div class="card-body" *ngIf="
          (resturantBankRequestDetails !== undefined && resturantBankRequestDetails !== null) ||
          isLoading
        ">
      <div class="row">
        <div class="col-sm-10 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <div class="mb-3">
                <p class="mb-0"><small>Bank Name:</small> </p>
                <span class="text-gray-900">{{resturantBankRequestDetails?.bankName}}</span>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <div class="mb-3">
                <p class="mb-0"><small>Account No.:</small> </p>
                <span class="text-gray-900">{{resturantBankRequestDetails?.accountNumber}}</span>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <div class="mb-3">
                <p class="mb-0"><small>Branch Name:</small> </p>
                <span class="text-gray-900">{{resturantBankRequestDetails?.branchName}}</span>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <div class="mb-3">
                <p class="mb-0"><small>IFSC Code:</small> </p>
                <span class="text-gray-900">{{resturantBankRequestDetails?.ifscCode}}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="card-body" *ngIf="
          (resturantBankRequestDetails === undefined || resturantBankRequestDetails === null) &&
          !isLoading
        ">
      <div class="no-image-area">
        <p class="title">No Bank Information Found</p>
      </div>
    </div>
  </div>

  <div class="row">

    <div class="col-xl-12 col-lg-12">
      <div class="card shadow mb-4">
        <div class="card-header py-3 align-items-center">
          <div class="row align-items-center">
            <div class="col-lg-9 col-md-6 col-sm-12 text-left">
              <h6 class="m-0 font-weight-bold text-primary">All Registered Employees</h6>
            </div>
            <!-- <div class="col-lg-3 col-md-6 col-sm-12 text-right">
                    <div class="dataTables_filter d-flex align-items-center">
                      <label class="mr-2 mb-0">Search:</label><input type="search" class="form-control form-control-sm" placeholder="e.g. name" aria-controls="dataTable">
                    </div>
                  </div> -->
          </div>
        </div>
        <div class="card-body" *ngIf="
              (resturantsEmployeeData !== undefined &&
                resturantsEmployeeData !== null &&
                resturantsEmployeeData.length > 0) ||
              isLoading
            ">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>E-mail</th>
                  <th>Contact No.</th>
                  <th>Registered Since</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="t-loading" *ngIf="isLoading">
                  <td colspan="6" class="text-center">
                    <div class="spinner-grow" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
                <tr *ngFor="let item of resturantsEmployeeData">
                  <td>1</td>
                  <td>{{item?.employee?.firstName}} {{item?.employee?.lastName}}</td>
                  <td>{{
                    item?.employee?.email
                    }}</td>
                  <td>{{
                    item?.employee?.phone
                    }}</td>
                  <td>{{ item?.createdAt | date: "medium" }}</td>
                  <td>
                    <a *ngIf="item.status===0 || item.status===2" href="javascript:void(0);"
                      (click)="acceptedEmployee(item?.id)" class="btn btn-sm btn-primary btn-icon-split">
                      <span class="icon text-white-50">
                        <i class="fas fa-check"></i>
                      </span>
                      <span class="text">Mark Employee</span>
                    </a>
                    <a (click)="rejectedEmployee(item?.id)" *ngIf="item.status===0 ||  item.status===1"
                      href="javascript:void(0);" class="btn btn-sm btn-danger btn-icon-split">
                      <span class="icon text-white-50">
                        <i class="fas fa-times"></i>
                      </span>
                      <span class="text">Unmark Employee</span>
                    </a>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" *ngIf="resturantsEmployeeData.length > 0">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info pt11px" id="dataTable_info" role="status" aria-live="polite">
                Showing {{ (selectedPage - 1) * pageSize + 1 }} to
                {{ (selectedPage - 1) * pageSize + resturantsEmployeeData.length }} of
                {{ totalLength }} entries
              </div>
            </div>
            <div class="col-sm-12 col-md-7">
              <div class="dataTables_paginate float-right paging_simple_numbers" id="dataTable_paginate">
                <ngb-pagination [maxSize]="5" [rotate]="true" [(page)]="finalPage" [pageSize]="pageSize"
                  [collectionSize]="totalLength" (pageChange)="setPaginationPage($event)">
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="
  (resturantsEmployeeData === undefined || resturantsEmployeeData.length === 0) &&
  !isLoading
">
          <div class="no-image-area">
            <p class="title">No Registered Employees Found</p>

          </div>
        </div>
      </div>
    </div>

  </div>

</div>
<!-- Accept Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Accept Status</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">Are you sure you want to accept this request ? <div class="alert alert-danger mt-2"
          *ngIf="errMsg" role="alert">
          {{errMsg}}
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" #aclsBtn type="button" data-dismiss="modal">Cancel</button>
        <button class="btn btn-primary" (click)="returantRequestIsAcceptOrReject(2)">Ok</button>
      </div>
    </div>
  </div>
</div>

<!-- Reject Modal -->
<div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Reject Status</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">Are you sure you want to reject this request ? <div class="alert alert-danger mt-2"
          *ngIf="errMsg" role="alert">
          {{errMsg}}
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" #rclsBtn type="button" data-dismiss="modal">Cancel</button>
        <button class="btn btn-primary" (click)="returantRequestIsAcceptOrReject(3)">Ok</button>
      </div>
    </div>
  </div>
</div>