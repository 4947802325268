import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { DriverDetailComponent } from './driver-detail/driver-detail.component';
import { DriverRequestComponent } from './driver-request.component';

const routes: Routes = [
  { path: "", component: DriverRequestComponent, canActivate: [AuthGuard] },
  {
    path: "driver-detail",
    component: DriverDetailComponent,
    canActivate: [AuthGuard]
  },
];

export const DriverRoutes = RouterModule.forChild(routes);
