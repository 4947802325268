import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private apiService: ApiService) { }

  dashBoardDataPoint() {
    const path = `/dashboard/dataPoint`;
    return this.apiService.getRequest(path, true);
  }

  getRestaurantRequestst(startDate, endDate) {
    const path = `/dashboard/restaurantRequests`;
    return this.apiService.getRequest(path + '?startDate=' + startDate + '&endDate=' + endDate, true);
  }

  cashbackAmount() {
    const path = `/dashboard/user/cashback`;
    return this.apiService.getRequest(path, true);
  }

  companyDashboardDataPoint(companyId){
    const path = `/dashboard/company/dataPoint?companyId=${companyId}`;
    return this.apiService.getRequest(path, true);
  }

}
